<template>
  <div>
    <!-- HEADER -->
    <v-row>
      <v-col :cols="6" :lg="9"> </v-col>

      <!-- Search input -->
      <v-col :cols="6" :lg="3" v-if="search">
        <v-form @submit.native.prevent @submit="onSearchUpdated">
          <v-text-field
            outlined
            dense
            v-model="keyword"
            clearable
            :label="$t('comps.ssrTable.search')"
            prepend-inner-icon="mdi-magnify"
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>

    <!-- TABLE BODY -->
    <v-data-table
      v-model="selected"
      hide-default-footer
      :headers="schema"
      :show-select="selectable"
      :single-select="!multiSelect"
      :items="rows"
      :items-per-page="paging.perPage"
      :server-items-length="paging.total"
      :loading="isLoading"
      :loading-text="$t('comps.ssrTable.loading')"
      @update:sort-by="onSortByUpdate"
      @update:sort-desc="onSortDescUpdate"
      @item-selected="onRowSelectionChanged"
      @toggle-select-all="onToggleSelectAll"
    >
      <!-- Customize header slots for searching/filtering feature -->
      <template
        v-for="col in schema"
        v-slot:[headerSlotNameMap[col.value]]="{ header }"
      >
        <span class="text-h5">{{ header.text }}</span>
      </template>

      <template
        v-for="col in schema"
        v-slot:[itemSlotNameMap[col.value]]="{ item }"
      >
        <slot :name="col.value" :item="item">
          {{ item[col.value] }}
        </slot>
      </template>
    </v-data-table>

    <!-- TABLE FOOTER -->
    <v-row v-if="!disablePagination">
      <!-- PAGINATION -->
      <v-col cols="4" class="pt-6">
        <v-pagination v-model="paging.current" :length="paginationLength">
        </v-pagination>
      </v-col>

      <v-col cols="2"></v-col>

      <v-col cols="6" class="d-flex justify-space-between align-center">
        <!-- TOTAL COUNT -->
        <span
          ><strong>{{ $t("comps.ssrTable.total") }}</strong
          >{{ paging.total }}</span
        >

        <!-- PER-PAGE SELECT -->
        <div style="width:120px;">
          <v-select
            :label="$t('comps.ssrTable.perPage')"
            v-model="paging.perPage"
            :items="perPageOptions"
          >
          </v-select>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SSRTable",
  props: {
    data: Array,
    dataUrl: String,
    dataKey: String,
    schema: Array,
    perPageOptions: {
      type: Array,
      default: () => {
        return [10, 20, 30];
      }
    },
    disablePagination: {
      type: Boolean,
      default: false
    },
    // searchOn: {
    //   type: Array,
    //   default: () => { return []; }
    // },
    search: {
      value: [Boolean, Array],
      default: false
    },

    selectable: Boolean,
    multiSelect: Boolean
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      let limit = this.paging.perPage;
      let startIndex = this.paging.perPage * (this.paging.current - 1);

      // If no data URL source given, treat this as local data array.
      if (!this.dataUrl) {
        // console.log('ROWS', this.data);
        if (this.data)
          this.fillTable(this.data.slice(startIndex, startIndex + limit));
        else this.rows = [];
        return;
      }

      this.isLoading = true;

      //NOTE: Should pagination be disabled, tune out paging parameters.
      if (this.disablePagination) {
        this.paging.current = 1;
        this.paging.total = 1;
        this.paging.perPage = -1;
      }

      // Construct get-data URL.
      let queryStringStarter = this.dataUrl.indexOf("?") > 0 ? "&" : "?";
      let getUrl = `${this.dataUrl}${queryStringStarter}limit=${limit}&startIndex=${startIndex}`;
      if (this.sort.by) {
        getUrl += `&sort=${this.sort.by}:${this.sort.ordering}`;
      }
      if (this.search && this.keyword && this.keyword.length) {
        if (!Array.isArray(this.search)) getUrl += `&keyword=${this.keyword}`;
        else getUrl += `&search=${this.search.join(",")}:${this.keyword}`;
      }

      // Make the request.
      axios
        .get(getUrl)
        .then(response => {
          this.fillTable(response.data);
        })
        .catch(err => {
          console.log("err:", err.response);
          //TODO: Show error indication.
          this.$emit(
            "load-error",
            err.response && err.response.data ? err.response.data : err
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    fillTable(data) {
      if (Array.isArray(data)) {
        this.rows = data;
        if (this.data) {
          // 如果外部是傳data進來，total應該是要等於資料長度
          this.paging.total = this.data.length;
        } else {
          this.paging.total = data.length;
        }
      } else if (this.dataKey) {
        this.rows = data[this.dataKey];
        this.paging.total = data["total"];
      } else throw new Error(`No valid list data given.`);
    },

    onSortByUpdate(sortBy) {
      console.warn(`[SSRTable] onSortByUpdate`, sortBy);
      this.sort.by = sortBy;
    },
    onSortDescUpdate(isDesc) {
      console.warn(`[SSRTable] onSortDescUpdate`, isDesc);
      this.sort.ordering = isDesc ? "desc" : "asc";
      this.loadData();
    },
    onSearchUpdated() {
      this.paging.current = 1;
      this.selected = [];
      this.loadData();
    },
    onRowSelectionChanged(row) {
      // console.warn(`[SSRTable] onRowSelectionChanged`, row);
      this.$emit("select-row", row.item, row.value);
    },

    onToggleSelectAll (event) {
      this.$emit("select-all", event);
    },
    /// Accessible methods.
    getCurrentList() {
      return this.rows;
    }
  },

  data() {
    return {
      isLoading: false,

      rows: [],

      paging: {
        current: 1,
        perPage: 10,
        total: 0
      },

      sort: {
        by: null,
        ordering: "asc"
      },
      keyword: "",
      selected: [],
    };
  },

  computed: {
    headerSlotNameMap() {
      let ret = {};
      this.schema.forEach(s => {
        ret[s.value] = `header.${s.value}`;
      });
      return ret;
    },
    itemSlotNameMap() {
      let ret = {};
      this.schema.forEach(s => {
        ret[s.value] = `item.${s.value}`;
      });
      return ret;
    },

    paginationLength() {
      let temp = Math.ceil(this.paging.total / this.paging.perPage);
      // console.log(`paginationLength computed`, temp,this.paging.total,this.paging.perPage );
      return temp;
    }
  },

  watch: {
    "paging.perPage": "loadData",
    "paging.current": "loadData",
    dataUrl: function() {
      this.paging.current = 1; //Reset the page no whenever the data URL changed.
      this.selected = [];
      this.loadData();
    },
    data: function() {
      this.paging.current = 1;
      this.selected = [];
      this.loadData();
    }
  }
};
</script>

<style scoped></style>
